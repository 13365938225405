import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { url } from "../config";

const BannersContext = createContext();

const BannersProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    const [banners, setBanner] = useState([]);

    async function getAll() {
        setLoading(true);

        try {
            var { data } = await axios.get(`${url}/banners`);
            setBanner(data);
            setLoading(false);
            console.log(banners);
        } catch (error) {
            console.error(error);
            return "err";
        }
    }

    useEffect(() => {
        getAll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            banners,
        }),
        [banners] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the Bannerscontext context to the children components
    return (
        <BannersContext.Provider value={contextValue}>{children}</BannersContext.Provider>
    );
};

export const useBanners = () => {
    return useContext(BannersContext);
};

export default BannersProvider;