import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GoogleAnalytics = ({ trackingId }) => {
    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(trackingId);

        // Track page view
        const trackPageView = () => {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: document.title });

        };

        // Track initial page load
        trackPageView();

        // Track page views on route change
        window.addEventListener('popstate', trackPageView);

        return () => {
            window.removeEventListener('popstate', trackPageView);
        };
    }, [trackingId]);

    return null;
};

export default GoogleAnalytics;