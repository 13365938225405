import { Container, Text, Image, Card, VStack, Button, HStack, useBreakpointValue, Stack, SimpleGrid, Box, AspectRatio } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { url } from '../../config';
import Slider from 'react-slick/lib/slider';
import ProductCard from '../common/ProductCard';
import { useCarts } from '../../providers/CartProvider';
import { useAuth } from '../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


export default function ProductDetails() {
    const params = useParams();
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const arrowShow = useBreakpointValue({ base: false, lg: true })
    var { carts, addCarts } = useCarts();
    var { logged } = useAuth();

    const nav = useNavigate();

    const settings = {
        dots: true,
        arrows: arrowShow,
        fade: true,
        infinite: false,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [categoryProducts, setcategoryProducts] = useState([]);
    const [uniqueColorVariants, setUniqueColorVariants] = useState([]); // eslint-disable-line no-unused-vars
    const [uniqueSizeVariants, setUniqueSizeVariants] = useState([]);
    const [sameSizeVariantsColors, setSameSizeVariantsColors] = useState([]);


    async function getProductDetails() {
        try {
            var { data } = await axios.get(`${url}/product/${params.slug}`);
            setProduct(data.data[0]);
            setcategoryProducts(data.category_products);

            let uniqueColors = data.other_variants.filter((product, index, self) =>
                index === self.findIndex((p) => p.colors.id === product.colors.id)
            );

            let uniqueSizes = data.other_variants.filter((product, index, self) =>
                index === self.findIndex((p) => p.sizes.id === product.sizes.id)
            );

            let sameSizeVariantsColors = data.other_variants.filter(variant => variant.sizes.id === data.data[0].sizes.id);
            console.log(sameSizeVariantsColors);

            setSameSizeVariantsColors(sameSizeVariantsColors);
            setUniqueColorVariants(uniqueColors);
            setUniqueSizeVariants(uniqueSizes);

            let imgs = [];
            if (data.data[0].product_variants['image1'] != null) {
                // console.log(data.data[0].product_variants['image1']);
                imgs.push(data.data[0].product_variants['image1']);
            }
            if (data.data[0].product_variants['image2'] != null) {
                // console.log(data.data[0].product_variants['image2']);
                imgs.push(data.data[0].product_variants['image2']);
            }
            if (data.data[0].product_variants['image3'] != null) {
                // console.log(data.data[0].product_variants['image3']);
                imgs.push(data.data[0].product_variants['image3']);
            }
            // console.log(imgs);
            setImages(imgs);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        // if (params.slug !== undefined) {
        //     getProductDetails();
        // }
        getProductDetails();
    }, [params.slug]) // eslint-disable-line react-hooks/exhaustive-deps

    if (product == null) {
        return <div>Loading...</div>
    }

    return (
        <Container maxW='7xl' my='8'>
            <VStack w='100%' spacing={{ base: '6', md: '12' }}>
                <Stack w='100%' direction={{ base: 'column', md: 'row' }} spacing={{ base: '8', md: '4' }}>
                    <HStack w={{ base: '100%', md: '50%' }}>

                        <Box w='100%'>
                            {images.length > 0 ? (
                                <Slider {...settings}>
                                    {images.map((url, index) => (
                                        <AspectRatio key={index} ratio={1 / 1}>
                                            <Box
                                                aspectRatio={1 / 1}
                                                w='100%'
                                                backgroundPosition="center center"
                                                backgroundRepeat="no-repeat"
                                                backgroundSize="contain"
                                                backgroundImage={`url(${url})`}
                                            />
                                        </AspectRatio>
                                    ))}
                                </Slider>
                            ) : <></>}
                        </Box>

                    </HStack>
                    <VStack alignItems='start' spacing='4' w={{ base: '100%', md: '50%' }}>
                        <HStack w='100%' alignItems='start' justifyContent='start' spacing='1'>
                            <Text fontWeight='800' fontSize='xl' display='inline-block'>{product.products.name} </Text>
                            {/* <Text fontWeight='800' fontSize='xl' display='inline-block'>{product.product_variants.size} - </Text>
                            <Text fontWeight='800' fontSize='xl' display='inline-block'>{product.product_variants.color}</Text> */}
                        </HStack>
                        <HStack justifyContent='start' w='100%' spacing='2'>
                            <HStack spacing='0'>
                                <Image src='/rupee_light.png' w='8px' me='2px' />
                                <Text as='h4' style={{ textDecoration: 'line-through' }} fontSize='md'>
                                    {product.product_variants.mrp}
                                </Text>
                            </HStack>

                            <HStack spacing='0'>
                                <Image src='/rupee.png' w='10px' me='2px' />
                                <Text as='h1' className='customfont' fontSize='lg'>
                                    {product.product_variants.selling_price}
                                </Text>
                            </HStack>

                            <Text
                                color='#ff5c5c'
                                fontSize={[12, 14, 18, 18]}>
                                ({Math.round(((product.product_variants.mrp - product.product_variants.selling_price) / product.product_variants.mrp) * 100)}% off)
                            </Text>
                        </HStack>

                        <VStack w='100%' alignItems='start'>
                            <HStack spacing='1'> <Text>Color : </Text>  <Text fontWeight='800'> {product.colors.name} </Text></HStack>
                            <HStack>
                                {sameSizeVariantsColors.map((ov, indx) => {
                                    return <VStack h='100%' justifyContent='space-between'>
                                        <a href={`/product/${ov.product_variants.slug}`}>
                                            <Box
                                                boxSizing='border-box'
                                                border={product.colors.id === ov.colors.id ? '5px solid #a7a7a7' : ''}
                                                key={indx}
                                                borderRadius='30px'
                                                w={product.colors.color_hex === ov.colors.name ? '42px' : '42px'}
                                                h={product.colors.color_hex === ov.colors.name ? '42px' : '42px'}
                                                bg={ov.colors.hex_code}
                                            >
                                            </Box>
                                        </a>
                                    </VStack>
                                })}
                            </HStack>
                        </VStack>

                        <VStack w='100%' alignItems='start'>
                            <HStack spacing='1'>
                                <Text>Size : </Text>
                                <Text fontWeight='800'> {product.sizes.name}</Text>
                            </HStack>
                            <HStack>
                                {uniqueSizeVariants.map((ov, indx) => {
                                    return <a href={`/product/${ov.product_variants.slug}`}>
                                        <Box
                                            key={indx}
                                            bg={product.sizes.name === ov.sizes.name ? '#1c1c1c' : 'transparent'}
                                            display='flex'
                                            w='50px'
                                            h='38px'
                                            border='1px solid #1c1c1c'
                                            borderRadius='8px'
                                            justifyContent='center'
                                            alignItems='center'
                                        >
                                            <Text
                                                className='customFont'
                                                fontWeight='800'
                                                alignItems='center'
                                                color={product.sizes.name === ov.sizes.name ? 'white' : '#1d1d1d'}
                                            >{ov.sizes.name}</Text>
                                        </Box>
                                    </a>
                                })}
                            </HStack>
                            <Text color='muted'>Select a size to see available colors</Text>
                        </VStack> 

                        {logged ?
                            <>
                                {(carts.filter((c) => c.product_id === product.products.id && c.product_variant_id === product.product_variants.id).length > 0) ? <>
                                    <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0' onClick={() => addCarts(product.products.id, product.product_variants.id, 0)}>
                                        Remove From Cart
                                    </Button>
                                </> : <>
                                    {product.product_variants.stock > 0 ?
                                        <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0' onClick={() => addCarts(product.products.id, product.product_variants.id, 1)}>
                                            Add to Cart
                                        </Button>
                                        : <Text color='red.500'>Out of Stock</Text>
                                    }
                                </>}
                            </>
                            :
                            <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0' onClick={() => { toast.warning("Please login to continue !"); nav('/login') }}>
                                Add to Cart
                            </Button>
                        }


                        <VStack alignItems='start'>
                            <Text className='customFont' fontSize='2xl' fontWeight='800'>Product Highlights</Text>
                            <Box px='4'>
                                <ul>
                                    <li>Quality Craftsmanship</li>
                                    <li>Soft and Breathable Fabric</li>
                                    <li>Premium Supima Cotton</li>
                                    <li>Modern fit</li>
                                    <li>Sustainability</li>
                                </ul>
                            </Box>

                        </VStack>
                    </VStack>
                </Stack>

                <SimpleGrid columns={[1, 1, 1, 3]} w='100%' spacing={{ base: '4', md: '8' }} px='0'>
                    <Card w='100%' shadow='sm' p='4'>
                        <VStack w='100%' alignItems='start'>
                            <Text as='h2' className='customFont' fontSize='lg'>Care Details</Text>
                            <Text fontSize='md'>{product.products.care_details}</Text>
                        </VStack>
                    </Card>
                    <Card w='100%' shadow='sm' p='4'>
                        <VStack w='100%' alignItems='start'>
                            <Text as='h2' className='customFont' fontSize='lg'>Product Highlights</Text>
                            <Text fontSize='md'>{product.products.product_highlights}</Text>
                        </VStack>
                    </Card>
                    <Card w='100%' shadow='sm' p='4'>
                        <VStack w='100%' alignItems='start'>
                            <Text as='h2' className='customFont' fontSize='lg'>Return Policy</Text>
                            <Text fontSize='md'>{product.products.return_policy}</Text>
                        </VStack>
                    </Card>
                </SimpleGrid>

                <Text alignItems='start' w='100%' className='customFont' as='h1' fontSize='2xl'>More Products in this Category</Text>

                <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                    {categoryProducts.map((p, index) => {
                        return <ProductCard key={index} product={p} />
                    })}
                </SimpleGrid>

                <Text alignItems='start' w='100%' className='customFont' as='h1' fontSize='2xl'>Product Reviews</Text>

                <SimpleGrid w='100%' columns={[2, 2, 3, 4]} spacing={[4, 4, 6, 8]}>
                    <Text>No reviews yet</Text>
                </SimpleGrid>

            </VStack >
        </Container >
    )
}
