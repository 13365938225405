import {
    Container,
    VStack,
    Divider,
    HStack,
    Button,
    Flex,
    Image,
    Text,
    Card,
    Heading,
    Box,
    Stack,
    RadioGroup,
    Radio
} from '@chakra-ui/react';
import React from 'react'
import { useCarts } from '../../providers/CartProvider';
// import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useUser } from '../../providers/UserProvider';
import { Link } from 'react-router-dom';
import { useOrders } from '../../providers/OrderProvider';

export default function CheckOut() {

    const { address } = useUser();
    const [selectedAddress, setSelectedAddress] = React.useState(0)
    var { carts, totalPrice } = useCarts();
    const [value, setValue] = React.useState('ONLINE')

    const { createOrder } = useOrders();

    const calculateDiscount = (price, sellPrice) => {
        return Math.round(((price - sellPrice) / price) * 100);
    };

    function orderproduct() {
        if (selectedAddress === 0) {
            toast.error("Please select an address to deliver");
            return;
        }
        if (value === 'COD') {
            toast.error("Cash on Delivery is not available right now. Please select Cash on Delivery");
        } else {
            console.log(selectedAddress);
            
            createOrder(selectedAddress);
        }
    }

    return (
        <Container maxW='7xl' my='8'>
            <Text className='customFont' as='h1' fontWeight='bold' fontSize='2xl' mb='2'>CheckOut</Text>

            {carts.length === 0 && <>
                <VStack w='100%'>
                    <Text>Your Cart is Empty</Text>
                    <Text>Please explore our wide range of collections.</Text>
                </VStack>
            </>
            }

            {carts.length !== 0 &&
                <Flex direction={{ base: 'column', lg: 'row' }}>
                    <VStack w={{ base: '100%', lg: '70%' }} spacing={4}>
                        {carts.map((item) => (
                            <Box key={item.id} borderWidth="1px" borderRadius="lg" bg={'#fcfcfc'} w="100%">
                                <Flex p={5} align="center">
                                    <Image boxSize="120px" objectFit="cover" src={item.product.product_variants.image1} alt={item.product.products.name} />
                                    <VStack align="start" ml={5}>
                                        <Text fontSize="lg" fontWeight="bold">{item.product.products.name}</Text>
                                        <Text fontSize='sm' color="gray.500">Size: {item.product.product_variants.size}</Text>
                                        <HStack>
                                            <Text fontSize='sm' color="gray.500" textDecoration="line-through">₹{item.product.product_variants.mrp}</Text>
                                            <Text fontSize="md" fontWeight="bold">₹{item.product.product_variants.selling_price}</Text>
                                            <Text fontSize='xs' color='#ff5c5c'>({calculateDiscount(item.product.product_variants.mrp, item.product.product_variants.selling_price)}% off)</Text>
                                        </HStack>
                                        <HStack>
                                            <Text>Quantity : {item.quantity}</Text>
                                        </HStack>
                                    </VStack>
                                </Flex>
                                <Divider />
                            </Box>
                        ))}
                    </VStack>
                    <VStack w={{ base: '100%', lg: '40%' }}>
                        <Box
                            w={{ base: '100%', lg: '100%' }}
                            p={5}
                            borderWidth="1px"
                            borderRadius="lg"
                            bg={'#fcfcfc'}
                            ml={{ lg: '18px' }}
                            mt={{ base: '18px', lg: '0' }}
                        >
                            <Heading size="md" mb={5}>Select Delivery Address</Heading>
                            <VStack spacing='3' w='100%'>
                                {address.map((add, idx) => {
                                    return (
                                        <Card
                                            bg={selectedAddress.id === add.id ? 'green' : 'white'}
                                            color={selectedAddress.id === add.id ? 'white' : '#1c1c1c'}
                                            key={idx}
                                            onClick={(val) => {
                                                // console.log(add.id);
                                                setSelectedAddress(add);
                                            }}
                                            w='100%'
                                            p='3'>
                                            <Text>{add.address}</Text>
                                            <Text>{add.phone}</Text>
                                            <Text>{add.city} {add.pincode} {add.state} </Text>
                                        </Card>
                                    )
                                })}
                                <Link to='/address/add'>
                                    <Button bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>Add New Address</Button>
                                </Link>
                            </VStack>
                        </Box>
                        <Box
                            w={{ base: '100%', lg: '100%' }}
                            p={5}
                            borderWidth="1px"
                            borderRadius="lg"
                            bg={'#fcfcfc'}
                            ml={{ lg: '18px' }}
                            mt={{ base: '18px', lg: '0' }}
                        >
                            <Heading size="md" mb={5}>Order Summary</Heading>
                            <VStack spacing={4}>
                                <Flex w="100%" justify="space-between">
                                    <Text>Subtotal</Text>
                                    <Text>₹{totalPrice}</Text>
                                </Flex>
                                <Flex w="100%" justify="space-between">
                                    <Text>Shipping</Text>
                                    <Text>Free</Text>
                                </Flex>
                                <Divider />
                                <Flex w="100%" justify="space-between" fontWeight="bold">
                                    <Text>Total</Text>
                                    <Text>₹{totalPrice}</Text>
                                </Flex>
                                <Divider />
                                <VStack w='100%' alignItems='start'>
                                    <Heading size="md" mb={5}>Payment Method</Heading>
                                    <RadioGroup onChange={setValue} value={value}>
                                        <Stack direction='column'>
                                            <Radio size='lg' value='ONLINE'><Text>Credit/Debit Card & UPI</Text></Radio>
                                            {/* <Radio size='lg' value='COD'>Cash On Delivery</Radio> */}
                                        </Stack>
                                    </RadioGroup>
                                </VStack>
                                <Button onClick={orderproduct} type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>
                                    Order Now
                                </Button>
                            </VStack>
                        </Box>
                    </VStack>
                </Flex >
            }
        </Container >
    )
}




