import {
    Container,
    VStack,
    HStack,
    Button,
    Flex,
    Image,
    Text,
    Badge,
    Box,
    Spacer
} from '@chakra-ui/react';
import React from 'react'
import { useOrders } from '../../providers/OrderProvider';
import moment from 'moment';
import { Link } from 'react-router-dom';

export default function MyOrders() {

    const { orders } = useOrders();

    return (
        <Container maxW='6xl' my='8'>
            <Text className='customFont' as='h1' fontWeight='bold' fontSize='2xl' mb='2'>My Orders</Text>
            <VStack spacing={4}>
                {orders.map((order) => (
                    <Box key={order.id} borderWidth="1px" bg={'#fcfcfc'} borderRadius="lg" w="100%" p={5}>
                        <Flex align="start">
                            <Image boxSize="120px" objectFit="cover" src={order.product_details[0].product_variants.image1} alt={order.product_details[0].products.name} />
                            <VStack align="start" ml={5} flex={1}>
                                <Text fontSize="lg" fontWeight="bold">{order.product_details[0].products.name} + {order.product_details.length - 1} other items</Text>
                                <HStack>
                                    <Text fontSize="md" color="gray.600">Order ID: {order.id}</Text>
                                </HStack>
                                <Text fontSize="md" fontWeight="bold">Order Total : ₹{order.total}</Text>
                                <Badge colorScheme={order.status === 'Delivered' ? 'green' : 'orange'}>{order.status}</Badge>
                                <Text fontSize="sm" color="gray.500">Order Date: {moment(order.created_at).format('DD-MM-YYYY hh:mm A')}</Text>
                                <Spacer />
                                <HStack>

                                    <Button type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>
                                        View Details
                                    </Button>
                                   
                                    {order.status === 'created' &&
                                        <Button as={Link} to='/pay' state={order} type="submit" bg="#1c1c1c" _hover={{ bg: '#1d1d1d' }} color="#fefefe" py='6' borderRadius='0'>
                                            Pay Now
                                        </Button>
                                    }
                                </HStack>
                            </VStack>
                        </Flex>
                    </Box>
                ))}
            </VStack>
        </Container>
    )
}





