import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import PrivateRoutes from './components/utils/PrivateRoutes';
import AuthProvider from './providers/AuthProvider';
import Navbar from './components/common/Navbar';
import Home from './components/pages/home/Home';
import Footer from './components/common/Footer';
import Contact from './components/pages/Contact';
import Login from './components/pages/auth/Login';
import SignUp from './components/pages/auth/SignUp';
import ForgotPassword from './components/pages/auth/ForgotPassword';
import OtpPage from './components/pages/auth/OtpPage';
import About from './components/pages/About';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import TermsAndConditions from './components/pages/TermsAndConditions';
import CategoriesProvider from './providers/CategoriesProvider';
import BannersProvider from './providers/BannersProvider';
import ResetPassword from './components/pages/auth/ResetPassword';
import ProductProvider from './providers/ProductProvider';
import Disclaimer from './components/pages/Disclaimer';
import PaymentPolicy from './components/pages/PaymentPolicy';
import ContactsProvider from './providers/ContactProvider';
import ShippingAndDelivery from './components/pages/ShippingAndDelivery';
import CancellationAndRefund from './components/pages/CancellationAndRefund';
import MyProfile from './components/pages/user/MyProfile';
import CategoryProducts from './components/pages/categories/CategoryProducts';
import ScrollToTop from './components/utils/ScrollToTop';
import Address from './components/pages/user/Address';
import ProductDetails from './components/pages/ProductDetails';
import AddAddress from './components/pages/user/AddAddress';
import Cart from './components/pages/Cart';
import UpdateAddress from './components/pages/user/UpdateAddress';
import CartProvider from './providers/CartProvider';
import Shop from './components/pages/Shop';
import MyOrders from './components/pages/MyOrders';
import CheckOut from './components/pages/CheckOut';
import Search from './components/pages/Search';
import ReactGA from 'react-ga4';
import GoogleAnalytics from './components/common/GoogleAnalytics';
import OrdersProvider from './providers/OrderProvider';
import PayForOrder from './components/pages/PayForOrder';

function App() {

  ReactGA.initialize("G-GL802Z7631");

  return (
    <AuthProvider key={'authprovider'}>
      <Router>
        <CategoriesProvider>
          <BannersProvider>
            <ProductProvider>
              <CartProvider>
                <OrdersProvider>
                  <ContactsProvider>
                    <ScrollToTop />
                    <GoogleAnalytics trackingId="G-GL802Z7631" />
                    <Navbar />
                    <Routes>
                      <Route element={<PrivateRoutes />}>
                        <Route path='/my-profile' element={<MyProfile />} />
                        <Route path='/orders' element={<MyOrders />} />
                        <Route path='/address' element={<Address />} />
                        <Route path='/address/add' element={<AddAddress />} />
                        <Route path='/address/update' element={<UpdateAddress />} />
                        <Route path='/cart' element={<Cart />} />
                        <Route path='/checkout' element={<CheckOut />} />
                      </Route>
                      <Route path='/' element={<Home />} />
                      <Route path='/login' element={<Login />} />
                      <Route path='/signup' element={<SignUp />} />
                      <Route path='/forgot-password' element={<ForgotPassword />} />
                      <Route path='/otp-page' element={<OtpPage />} />
                      <Route path='/reset-password' element={<ResetPassword />} />
                      <Route path='/categories' element={<Contact />} />
                      <Route path='/categories/:slug' element={<CategoryProducts />} />
                      <Route path='/product/:slug' element={<ProductDetails />} />
                      <Route path='/contact' element={<Contact />} />
                      <Route path='/about' element={<About />} />
                      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                      <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                      <Route path='/disclaimer' element={<Disclaimer />} />
                      <Route path='/paymentpolicy' element={<PaymentPolicy />} />
                      <Route path='/cancellation-and-refund' element={<CancellationAndRefund />} />
                      <Route path='/shipping-and-delivery' element={<ShippingAndDelivery />} />
                      <Route path='/shop' element={<Shop />} />
                      <Route path='/search' element={<Search />} />
                      <Route path='/pay' element={<PayForOrder />} />
                    </Routes>
                    <Footer />
                    <ToastContainer position='bottom-center' autoClose='1500' />
                  </ContactsProvider>
                </OrdersProvider>
              </CartProvider>
            </ProductProvider>
          </BannersProvider>
        </CategoriesProvider>
      </Router>
    </AuthProvider >
  );
}

export default App;