import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthProvider";
import { url } from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCarts } from "./CartProvider";

const OrdersContext = createContext();

const OrdersProvider = ({ children }) => {
    const navigate = useNavigate();

    const { getAll: getAllCarts } = useCarts();

    const [loading, setLoading] = useState(true);
    const { token, logged, logout } = useAuth();
    const [orders, setOrders] = useState([]);

    async function createOrder(address) {
        setLoading(true);
        console.log(token);
        if (!logged || !token) {
            return;
        }
        try {
            var res = await axios.post(
                `${url}/orders`,
                {
                    address: address
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(res.data);
            // await getAll();
            // refresh cart 
            getAllCarts();
            // send to payment page 
            toast.success(res.data.success);
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                console.log(error.response.data.errors);

                if (error.response.data.errors.includes("Out of stock")) {
                    toast.error("Some products are out of stock");
                    navigate("/cart");
                } else {
                    toast.error(error.response.data.errors);
                }
            }
            if (error.response.status === 401) {
                // toast.error("Login to continue...");
                logout();
                navigate('/login');
            }
            return "err";
        }
    }

    async function getAll() {
        console.log("GetAll");
        setLoading(true);
        if (!logged) {
            return;
        }
        try {
            var res = await axios.get(
                `${url}/orders`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                }
            );
            console.log(res.data);

            setOrders(res.data);
            // console.log(carts)
        } catch (error) {
            console.error(error);
            if (error.response.status === 400) {
                toast.error(error.response.data.errors);
            }
            if (error.response.status === 401) {
                toast.error("Logged out. Please log in again");
                logout();
            }
            return "err";
        }
    }

    useEffect(() => {
        getAll();
    }, [logged]); // eslint-disable-line react-hooks/exhaustive-deps

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            loading,
            orders,
            createOrder,
        }),
        [orders, logged, loading] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // Provide the OrdersContext context to the children components
    return (
        <OrdersContext.Provider value={contextValue}>{children}</OrdersContext.Provider>
    );
};

export const useOrders = () => {
    return useContext(OrdersContext);
};

export default OrdersProvider;