import { Button, Card, CardBody, Container, Text, VStack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useRazorpay } from "react-razorpay";
import { useAuth } from "../../providers/AuthProvider";

export default function PayForOrder() {

    const { error, isLoading, Razorpay } = useRazorpay();
    const { user } = useAuth();

    const { state } = useLocation();

    const handlePayment = () => {
        const options = {
            key: "",
            amount: state.total * 100, // Amount in paise
            currency: "INR",
            name: "Vocalthread",
            description: "Vocalthread Payment",
            // order_id: state.id, // Generate order_id on server
            handler: (response) => {
                console.log(response);
                alert("Payment Successful!");
            },
            prefill: {
                name: user.name,
                // email: user.email,
                // contact: user.phone,
            },
            theme: {
                // color: "#F37254",
            },
        };

        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
    };

    return (
        <Container my='6'>

            {error && <Text color='red'>{error}</Text>}

            {isLoading && <Text>Loading...</Text>}

            <Text className='customFont' as='h1' fontWeight='bold' fontSize='2xl' mb='2'>Pay for Order</Text>

            <Card>
                <CardBody as={VStack} spacing={4} w='100%' align='start'>
                    <Text>Order ID : {state.id}</Text>
                    <Text>Total Amount : ₹{state.total}</Text>
                    <Text>Order Date : {state.created_at}</Text>
                    <Text>Order Status : {state.status}</Text>

                    <Button onClick={handlePayment}>Make Payment</Button>

                </CardBody>
            </Card>
        </Container>
    );
}
